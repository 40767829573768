<template>
  <page :title="i18n.title" back>
    <div class="shop-warp">
      <div class="shop-head">
        <div class="shop-nav">
          <div
            :key="nav.type"
            class="shop-nav-btn"
            v-for="nav in shopNav"
            :class="{ active: nav.type === shopState }"
            @click="changeShopState(nav.type)"
          >
            {{ nav.name }}
          </div>
        </div>
      </div>
      <div class="shop-main" v-if="shopList.length">
        <div class="shop-item" v-for="item in shopList" :key="item.id">
          <shop-item :item="item"></shop-item>
        </div>
      </div>
      <result-msg
        v-else
        :loading="loading"
        :text="error"
        @click="loadList"
      ></result-msg>
    </div>
  </page>
</template>

<script>
import { mapState } from "vuex"
import { SHOP_LIST } from "../../apis/cabinet"
import ShopItem from "./shopItem.vue"
import ResultMsg from "../../components/result/msg.vue"
export default {
  components: {
    ShopItem,
    ResultMsg
  },
  computed: {
    ...mapState(["system"]),
    i18n() {
      return this.$t("shop")
    }
  },
  data() {
    return {
      error: "",
      loading: false,
      searchVal: "",
      shopList: [],
      shopState: 0,
      shopNav: [
        { type: 0, name: this.$t("shop.shop_nav_1") },
        { type: 1, name: this.$t("shop.shop_nav_2") },
        { type: 2, name: this.$t("shop.shop_nav_3") }
      ]
    }
  },
  methods: {
    // 加载列表
    loadList() {
      this.changeShopState(this.shopState)
    },
    changeShopState(state) {
      this.shopState = state
      let _query = {}
      if (state) _query = { ..._query, state }
      this.$loading(true)
      this.getNearbyShop(_query)
    },
    // 获取周边站点
    getNearbyShop(param = {}) {
      const { query = {} } = this.$route
      const queryDate = {
        zoomLevel: 4,
        showPrice: true,
        usePriceUnit: true,
        coordType: "WGS-84",
        lat: query.lat,
        lng: query.lng,
        ...param
      }
      // queryDate.lat = 22.706337998877682
      // queryDate.lng = 113.78354094922543
      let formdata = new FormData()
      Object.keys(queryDate).forEach((key) => {
        formdata.append(key, queryDate[key])
      })

      this.$post(
        SHOP_LIST,
        formdata,
        (resp) => {
          this.$loading(false)
          this.shopList = resp.list
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        },
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
    }
  },

  mounted() {
    this.getNearbyShop()
  }
}
</script>
<style lang="less" scoped>
.shop-warp {
  min-height: 100vh;
  padding-top: 120px;
  background-color: #fff;
}
.shop-head {
  position: relative;
  z-index: 1;
  padding: 36px 56px;
  .shop-nav {
    display: flex;
    justify-content: space-between;
    &-btn {
      padding: 6px 0;
      font-size: 32px;
      color: #333;
      &.active {
        color: #75bb48;
        padding-bottom: 12px;
        border-bottom: 3px solid #75bb48;
      }
    }
  }
}
.shop-main {
  padding: 0 56px 48px;
}
</style>
